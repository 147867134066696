<template>
  <v-dialog
    v-model="dialog"
    overlay-color="#000000"
    overlay-opacity="0.54"
    class="mem-dialog"
    :max-width="375"
    :fullscreen="isMobile"
  >
    <div class="addon-dialog">
      <div class="close-button" @click="$emit('input', false)">
        <span class="material-icons"> close </span>
      </div>
      <div class="dialog-image">
        <div>{{ question.questionText }}</div>
      </div>
      <div class="dialog-title">{{ $t("checkout_modal_select_size") }}</div>
      <div class="answers-list">
        <div class="list-item" v-for="(answer, index) in answers" :key="index">
          <div>{{ answer.answer }}</div>
          <template>
            <div
              v-if="answer.selectedQuantity === 0"
              class="selector-button"
              :class="{ 'is-disabled': disableIncrements }"
              @click="addAnswer(answer)"
            >
              <span class="material-icons"> add </span>
            </div>
            <div v-else class="quantity-selectors">
              <div class="selector-button" @click="removeAnswer(answer)">
                <span class="material-icons"> remove </span>
              </div>
              <div>{{ answer.selectedQuantity }}</div>
              <div
                class="selector-button"
                :class="{ 'is-disabled': disableIncrements }"
                @click="addAnswer(answer)"
              >
                <span class="material-icons"> add </span>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- <div class="result-list">
        <div v-for="(item, index) in selectedItems" :key="index">
          <span>{{ item.answer }}</span>
        </div>
      </div> -->
      <div class="button-wrap">
        <mem-button @click="confirmChanges">{{ $t("confirm") }}</mem-button>
      </div>
      <!-- <div>{{ disableIncrements }}</div> -->
    </div>
  </v-dialog>
</template>
<script>
import { sumBy, findLastIndex, map } from "lodash";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: {
    value: Boolean,
    maxQuantity: Number,
    question: Object,
  },
  data: () => ({
    selectedItems: [],
    answers: [],
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    totalQuantity() {
      return sumBy(this.answers, "selectedQuantity");
    },

    disableIncrements() {
      return this.totalQuantity >= this.maxQuantity;
    },
    isMobile() {
      // TODO: Find a way to avoid vuetify variable use
      return this.$vuetify.breakpoint.width < 499;
    },
  },
  methods: {
    addAnswer(answer) {
      if (this.disableIncrements) return;
      answer.selectedQuantity++;
      this.selectedItems.push(answer);
    },
    removeAnswer(answer) {
      answer.selectedQuantity--;
      let answerIndex = findLastIndex(this.selectedItems, {
        id: answer.id,
      });
      this.selectedItems.splice(answerIndex, 1);
    },
    confirmChanges() {
      this.$emit("on-confirm", {
        answers: this.answers,
        selectedAnswers: this.selectedItems,
      });
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.selectedItems = [...this.question.memAnswers];
          this.answers = map(this.question.questionAnswers, (answer) => {
            return { ...answer };
          });
          return;
        }
        this.selectedItems = [];
        this.answers = [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.addon-dialog {
  background-color: #ffffff;
  color: #000000;
  position: relative;
  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    @include cursorPointer;
    .material-icons {
      color: #ffffff;
    }
  }
  .dialog-image {
    background-image: url("https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/large_t.png");
    background-size: cover;
    height: 308px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    color: #f2f2f2;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    @media screen and (max-width: 375px) {
      min-width: 101%;
    }
  }
  .dialog-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.18px;
    padding: 38px 24px 10px 24px;
  }
  .answers-list {
    padding: 0 24px;
    padding-bottom: 38px;
    .list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #e1e1e1;
      }
    }
    .quantity-selectors {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #eaeaea;
      border-radius: 32px;

      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
    .selector-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #eaeaea;
      border-radius: 32px;
      height: 36px;
      width: 36px;
      @include cursorPointer;
      &.is-disabled {
        pointer-events: none;
        .material-icons {
          color: #909090;
        }
      }
    }
  }
  .button-wrap {
    padding: 24px;
    position: sticky;
    bottom: 0;

    display: grid;
    // border-bottom: 1px solid rgba(34, 34, 34, 0.1);
    background: #fff;
    box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.15);
    animation: el-opacity 0.96s;
  }

  @keyframes el-opacity {
    from {
      opacity: 0;
      position: relative;
    }
    to {
      opacity: 1;
      position: sticky;
    }
  }
}
</style>